import React, {Component, useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import _ from 'lodash'
import amplitude from '$src/analytics/amplitude'
import {Portal} from 'react-portal'
import SvgIcon from '$common/components/icons/SvgIcon'
import copyIcon from '$assets/icons/copy.svg'
import frankSpinner from '$assets/icons/frankSpinner.svg'
import checkIcon from '$assets/icons/checkSingleWhite.svg'
import GenericButton from '$common/components/buttons/GenericButton'
import ConfirmVoucherRedeem from './ConfirmVoucherRedeem'
import AffiliateLinkDialog from './AffiliateLinkDialog'
import {Offer} from '$src/offers/types'
import {getOffer} from '$src/offers/selectors'
import {isFetching, getAsyncError} from '$src/fetching/selectors'
import {unsetAsyncErrors} from '$src/fetching/sagas'
import {getPreferredLang} from '$src/i18n/selectors'
import fetchUniqueCode from '$src/offers/sagas/fetchUniqueCode'
import redeemVoucherByKey from '$src/offers/sagas/redeemVoucher'
import P from '$common/components/P'
import align from '$common/grid/align.css'
import css from './index.css'
import grid from '$common/grid/grid.css'
import {
  withRunSaga,
  WithRunSagaProps,
  resolveCss,
  WithTranslateProps,
  withTranslate,
  isMobile
} from '$common/utils'
import {getAppboy} from '$common/pgWebSeparatorUtils'
import delay from '$src/common/utils/delay'
import postCanRedeem from '$src/offers/sagas/postCanRedeem'

const OFFER_TYPE = {
  ONLINE: 1,
  STORE: 2
}

type State = {
  copied?: boolean
  codeFetched: boolean
  showVoucherConfirm: boolean
  shouldShowAffiliateLinkDialog: boolean
  shouldShowCopiedIcon: boolean
  offerUrl?: string
}

type Props = WithTranslateProps &
  WithRunSagaProps & {
    item: Offer
    fetchingCode: boolean
    fetchCodeError?: object
    ctaDescKey: string
    itemKey: string
    css?: {}
    languageCode: 'fi' | 'en' | 'sv'
  }

class DiscountBox extends Component<Props, State> {
  static defaultProps = {item: {}}

  resetCopiedIconTimer = null
  hideAffliateDialogTimer = null
  delayTimer = null

  state = {
    copied: false,
    codeFetched: false,
    showVoucherConfirm: false,
    offerUrl: null,
    shouldShowAffiliateLinkDialog: false,
    shouldShowCopiedIcon: false,
    redeemOnce: {
      isLoaded: true,
      text: this.props.t('offer.redeemOnce.buttonDefault')
    }
  }
  offer: Offer = this.props.item

  code = undefined

  componentDidMount() {
    this.props.discountBoxRef.current = this.onClickOfferLink
  }

  UNSAFE_componentWillMount() {
    const {
      itemKey,
      item: {offerUrl}
    } = this.props
    if (offerUrl) {
      this.setState({offerUrl})
    }
  }

  componentWillUnmount() {
    this.props.runSaga(unsetAsyncErrors, 'offerUniqueCode')
    if (this.resetCopiedIconTimer) clearTimeout(this.resetCopiedIconTimer)
    if (this.hideAffliateDialogTimer) clearTimeout(this.hideAffliateDialogTimer)
    this.hideAffiliateLinkDialog()
  }

  writeDiscountCodeToClipboard = async (element: any) => {
    let copiedSuccessfully = false
    if (window.cordova) {
      try {
        window.cordova.plugins.clipboard.copy(element.value)
        copiedSuccessfully = true
      } catch (error) {
        // Display an error message to the user
        alert('Oops, unable to copy. Please try again.')
      }
    } else {
      try {
        await navigator.clipboard.writeText(element.value)
        copiedSuccessfully = true
      } catch (error) {
        // Display an error message to the user
        alert('Oops, unable to copy. Please try again.')
      }
    }
    if (copiedSuccessfully) {
      this.setState({copied: true, shouldShowCopiedIcon: true})
      this.resetCopiedIconTimer = setTimeout(
        () => this.setState({shouldShowCopiedIcon: false}),
        2000
      )
      const type = this.props.item.isVoucher ? 'voucher' : 'offer'
      getAppboy().logCustomEvent(
        `Copied ${type} code`,
        _.pick(this.props.item, ['key', 'advertiser'])
      )
    }
  }

  fetchCode = async () => {
    const {runSaga, languageCode, itemKey: key} = this.props
    await runSaga(fetchUniqueCode, {key, languageCode})
    this.setState({codeFetched: true})
  }

  codeNeedsToBeFetched = () => {
    const item = this.props.item
    return (
      (item.discountType === 'UNIQUE_DISCOUNT' ||
        item.discountType === 'UNIQUE_ONE_TIME_OFFER') &&
      !this.state.codeFetched &&
      !item.discountCode
    )
  }

  onClickOfferLink = async () => {
    const {item, itemKey} = this.props
    amplitude.logEvent('visitAdvertiserOffer', {
      offerId: itemKey,
      advertiser: item.advertiser,
      shortDescription: item.shortDescription,
      categories: item.categories.join(','),
      type: item.isVoucher ? 'voucher' : 'offer'
    })
    getAppboy().logCustomEvent('Open website link', {
      Url: this.state.offerUrl,
      Key: item.key,
      Advertiser: item.advertiser,
      ShortDescription: item.shortDescription,
      Categories: item.categories.join(','),
      Type: item.isVoucher ? 'voucher' : 'offer'
    })
    getAppboy().requestImmediateDataFlush() // Flush before navigating outside the application

    if (item.isAffiliate) {
      if (this.codeNeedsToBeFetched()) {
        await this.fetchCode()
      }
      this.writeDiscountCodeToClipboard(this.code)
      this.showAffiliateLinkDialog()
      this.delayTimer = delay(3000)
      await this.delayTimer.promise
    }

    if (window.cordova) {
      window.open(this.state.offerUrl, '_system')
    } else {
      window.open(this.state.offerUrl, '_blank', 'noopener,noreferrer')
    }
    this.hideAffliateDialogTimer = setTimeout(
      this.hideAffiliateLinkDialog,
      1000
    )
  }

  showAffiliateLinkDialog = () => {
    this.setState({shouldShowAffiliateLinkDialog: true})
    document.body.classList.add('scroll-lock')
  }

  hideAffiliateLinkDialog = () => {
    this.setState({shouldShowAffiliateLinkDialog: false})
    document.body.classList.remove('scroll-lock')
    if (this.delayTimer) this.delayTimer.cancel()
  }
  canRedeemOffer = async () => {
    const offerId = this.props.itemKey
    const userId = this.props.userId
    this.setState({
      redeemOnce: {isLoaded: false, text: ''}
    })
    try {
      const res = await this.props.runSaga(postCanRedeem, {
        offerId: offerId,
        userId: userId
      })
      if (res.canRedeem) {
        this.setState({
          redeemOnce: {
            isLoaded: true,
            text: this.props.t('offer.redeemOnce.buttonSuccessful')
          }
        })
      } else {
        this.setState({
          redeemOnce: {
            isLoaded: true,
            text: this.props.t('offer.redeemOnce.buttonAlreadyRedeemed')
          }
        })
      }
    } catch (error) {
      this.setState({
        redeemOnce: {
          isLoaded: true,
          text: this.props.t('offer.redeemOnce.buttonError')
        }
      })
    }
  }

  render() {
    const {
      item,
      item: {discountCode}
    } = this.props
    const classes = resolveCss(css, this.props.css, grid, align)
    return (
      <div className={css.discountBox}>
        <textarea
          readOnly
          style={{zIndex: -1, position: 'absolute'}}
          ref={(ref) => {
            this.code = ref
          }}
          value={discountCode}
        />
        {this.renderOfferCode(classes)}
        {this.state.offerUrl && (
          <GenericButton
            externalLink
            isFullWidth
            style="bold"
            onClick={this.onClickOfferLink}
            surroundMargin={true}
          >
            <span {...classes('offerUrlDesc')}>{item.offerUrlDescription}</span>
          </GenericButton>
        )}

        {this.state.showVoucherConfirm && (
          <Portal node={document && document.getElementById('#portal')}>
            <ConfirmVoucherRedeem
              onCancel={this.cancelRedeemVoucher}
              onConfirm={this.redeemVoucher}
            />
          </Portal>
        )}

        {this.state.shouldShowAffiliateLinkDialog && (
          <Portal node={document && document.getElementById('#portal')}>
            <AffiliateLinkDialog />
          </Portal>
        )}
      </div>
    )
  }

  confirmRedeemVoucher = () => this.setState({showVoucherConfirm: true})
  cancelRedeemVoucher = () => this.setState({showVoucherConfirm: false})

  redeemVoucher = async () => {
    const {runSaga, item, itemKey} = this.props
    this.setState({showVoucherConfirm: false})
    getAppboy().logCustomEvent(
      `Redeemed voucher`,
      _.pick(item, ['key', 'advertiser'])
    )
    amplitude.logEvent('redeemVoucher', {offerId: itemKey})
    await runSaga(redeemVoucherByKey, {key: itemKey})
  }

  renderOfferCode = (classes) => {
    const {
      fetchCodeError,
      t,
      item: {
        hasDiscountCode,
        discountCode,
        redeemedAt,
        isVoucher,
        voucherType,
        isAffiliate,
        singleUse
      }
    } = this.props
    const {copied, shouldShowCopiedIcon} = this.state

    if (hasDiscountCode) {
      if (fetchCodeError) {
        return (
          <div {...classes('codesNotFoundBox', 'discountBoxItem')}>
            <P bold style="small">
              {t(fetchCodeError.message)}
            </P>
          </div>
        )
      }

      // Hide the discount code for affiliate offers, unless it's already copied.
      if (isAffiliate && !copied) return null

      if (this.codeNeedsToBeFetched()) {
        return (
          <div
            onClick={this.fetchCode}
            {...classes('showCodeBox', 'discountBoxItem')}
          >
            {this.props.fetchingCode && (
              <div {...classes('spinnerContainer')}>
                <SvgIcon css={{container: css.spinner}} src={frankSpinner} />
              </div>
            )}
            {this.props.t('offer.showCode')}
          </div>
        )
      } else {
        return (
          <div {...classes('discountCodeContainer', 'discountBoxItem')}>
            <P bold style="xSmall" key="1">
              {t('offer.discountCode')}:
            </P>
            <div {...classes('discountCode')}>
              <div {...classes('discountCodeText')}>{discountCode}</div>

              <div {...classes('copyIconContainer')}>
                <SvgIcon
                  css={{container: css.copyIcon}}
                  src={shouldShowCopiedIcon ? checkIcon : copyIcon}
                  onClick={() => this.writeDiscountCodeToClipboard(this.code)}
                />
              </div>
            </div>
          </div>
        )
      }
    }

    if (isVoucher && voucherType === String(OFFER_TYPE.STORE)) {
      if (isMobile()) {
        return (
          <button onClick={this.confirmRedeemVoucher} disabled={redeemedAt}>
            <div {...classes('voucherRedeemContainer', 'discountBoxItem')}>
              <div {...classes('voucherRedeem')}>
                <div {...classes('voucherRedeemText')}>
                  {redeemedAt
                    ? t('voucher.voucherRedeemed')
                    : t('voucher.useVoucher')}
                </div>
              </div>
            </div>
          </button>
        )
      } else {
        return <div>{t('voucher.useMobile')}</div>
      }
    }

    if (singleUse) {
      return (
        <button
          onClick={this.canRedeemOffer}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {this.state.redeemOnce.isLoaded && (
            <span>{this.state.redeemOnce.text}</span>
          )}
          {!this.state.redeemOnce.isLoaded && (
            <SvgIcon key="0" src={frankSpinner} />
          )}
        </button>
      )
    }
  }
}

export default compose(
  withTranslate(),
  withRunSaga(),
  connect((state, {itemKey}) => {
    return {
      fetchCodeError: getAsyncError(state, 'offerUniqueCode'),
      fetchingCode: isFetching(state, 'offerUniqueCode'),
      languageCode: getPreferredLang(state),
      item: getOffer(state, itemKey)
    }
  })
)(DiscountBox)
